// musicConfig.js
import { UserSession } from '@stacks/auth';

import track1Image from './audio/Josie_image.png';
import track1Audio from './audio/Track 1_ Josie Field - Radio Silence.wav';
import track2Image from './audio/Ard_image.png';
import track2Audio from './audio/Track 2_ Ard Matthews - You Make it Alright.wav';
import track3Image from './audio/Arno_image.png';
import track3Audio from './audio/Track 3_ Arno Carstens - Mortals.wav';
import track4Image from './audio/Kahn_image.png';
import track4Audio from './audio/Track 4_ Kahn Morbee - Buzzhead.wav';
import track5Image from './audio/Laurie_image.png';
import track5Audio from './audio/Track 5_ Laurie Levine - Grey.wav';
import track6Image from './audio/Jacques_image2.png';
import track6Audio from './audio/Track 6_ Jacques Moolman - Exile.wav';
import track7Image from './audio/Evert_Image.png';
import track7Audio from './audio/Track 7_ Evert Snyman - I Shouldnt Break.wav';
import track8Image from './audio/Xander_image.png';
import track8Audio from './audio/Track 8_ Gazelle - BBQ to Braai.wav';
import albumImage from './audio/Album_image.png';

// Player state and functions

export const musicPlayer = {
    auth: null, // Add this line at the top with other properties
    currentTrackIndex: 0,
    audioPlayer: new Audio(),
    isInitialized: false,
    elements: null,
    MAX_PLAY_TIME: 30,
    audioTimer: null,
    countdownInterval: null,
    currentItem: null, // Store current musicData item
    stickyPlayer: null,
    MAX_PLAY_TIME: null, // Will be set dynamically
    isPreviewMode: true,

    init() {
        console.log('Initializing music player');
        if (this.isInitialized) {
            console.log('Already initialized - preventing duplicate initialization');
            return;
        }

        // Add loading event listeners
        this.audioPlayer.addEventListener('loadstart', () => {
            console.log('Audio loading started');
            this.isLoading = true;
        });

        this.audioPlayer.addEventListener('canplaythrough', () => {
            console.log('Audio can play through');
            this.isLoading = false;
        });

        this.audioPlayer.addEventListener('error', (e) => {
            console.error('Audio loading error:', e);
            this.handleAudioError(e);
        });

        // Initialize sticky player reference
        this.stickyPlayer = document.getElementById('music-player-sticky');


        // Add ended event listener for auto-play
        this.audioPlayer.addEventListener('ended', () => {
            this.handleTrackEnd();
        });

        // Add timeupdate listener with preview mode check
        this.audioPlayer.addEventListener('timeupdate', () => {
            if (this.isPreviewMode && this.audioPlayer.currentTime >= this.MAX_PLAY_TIME) {
                this.handleTrackEnd();
            }
            this.updateProgress();
        });


        if (this.stickyPlayer) {
            // Initialize play/pause button
            const playPauseBtn = this.stickyPlayer.querySelector('#play-pause-btn');
            if (playPauseBtn) {
                playPauseBtn.addEventListener('click', (e) => {
                    console.log('Play/Pause button clicked', {
                        id: e.target.id,
                        paused: this.audioPlayer.paused
                    });
                    this.togglePlayPause();
                });
            }

            // Initialize prev/next buttons
            const prevBtn = this.stickyPlayer.querySelector('#prev-btn');
            const nextBtn = this.stickyPlayer.querySelector('#next-btn');
            if (prevBtn) prevBtn.addEventListener('click', () => this.previousTrack());
            if (nextBtn) nextBtn.addEventListener('click', () => this.nextTrack());

            // Initialize progress bar
            const progressBar = this.stickyPlayer.querySelector('#progress-bar');
            if (progressBar) {
                progressBar.addEventListener('input', (e) => {
                    const time = (e.target.value / 100) * this.MAX_PLAY_TIME;
                    this.audioPlayer.currentTime = time;
                });
            }

            // Initialize volume control
            const volumeControl = this.stickyPlayer.querySelector('#volume-control');
            if (volumeControl) {
                volumeControl.addEventListener('input', (e) => {
                    this.audioPlayer.volume = e.target.value;
                });
            }
        }



        const style = document.createElement('style');
        style.textContent = `
    .preview-badge {
        background: #ff4444;
        color: white;
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 0.8em;
        margin-left: 8px;
    }
`;
        document.head.appendChild(style);

        this.isInitialized = true;
        this.currentTrackIndex = 0;
        console.log('Music player initialized');
    },

    
    updateAlbumArtBlur() {
        const albumArt = document.querySelector('#album-art');
        if (!albumArt) {
            console.log('Album art element not found');
            return;
        }

        // Get the current URL and parse it
        const url = new URL(window.location.href);
        const host = url.host;
        const stxAddress = host.split('.')[0]; // Get stxAddress from subdomain
        const pageTitle = window.location.hash.slice(2); // Remove '/#' from hash
        const currentStxAddress = this.getCurrentStxAddress();

        console.log('Blur check:', {
            stxAddress,
            currentStxAddress,
            pageTitle,
            isDashboard: stxAddress?.toLowerCase() === currentStxAddress?.toLowerCase() && !pageTitle,
            currentFilter: albumArt.style.filter
        });


        // Check if we're in dashboard using the same logic as your router
        const isDashboard =
            stxAddress?.toLowerCase() === this.getCurrentStxAddress()?.toLowerCase() &&
            !pageTitle;

        // Apply or remove blur based on dashboard status
        albumArt.style.filter = isDashboard ? 'none' : 'blur(7px)';
    },

    getCurrentStxAddress() {
    

        console.log('Auth check:', {
            hasAuth: !!this.auth,
            isSignedIn: this.auth?.userSession.isUserSignedIn(),
            userData: this.auth?.userSession.loadUserData()
        });

        if (!this.auth) return null;

        if (this.auth.userSession.isUserSignedIn()) {
            const userData = this.auth.userSession.loadUserData();
            const network = window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet';
            const address = userData.profile.stxAddress[network];
            console.log('Got STX address:', address);
            return address;
        }
        return null;
    },
    // Enhanced handleTrackEnd with preview mode support
    handleTrackEnd() {
        console.log('Track ended, advancing to next track');

        // Reset current track state
        this.audioPlayer.pause();
        this.audioPlayer.currentTime = 0;
        this.resetAudioTimer();
        this.clearCountdown();

        // Calculate next track index with wrap-around
        const nextIndex = (this.currentTrackIndex + 1) % (this.currentItem?.songs?.length || 1);

        // If we've completed the playlist, stop playback
        if (nextIndex === 0) {
            this.updatePlayPauseButtons(false);
            return;
        }


        // Set and load next track
        this.setCurrentTrack(nextIndex);
        this.loadTrack(nextIndex, this.currentItem?.songs);

        // Auto-play the next track with error handling
        const playPromise = this.audioPlayer.play();
        if (playPromise) {
            playPromise
                .then(() => {
                    // Start timers if in preview mode
                    if (this.isPreviewMode) {
                        this.startAudioTimer();
                        this.startCountdown();
                    }
                    this.updatePlayPauseButtons(true);
                    this.showStickyPlayer();

                })
                .catch(error => {
                    console.warn('Auto-play prevented:', error);
                    this.updatePlayPauseButtons(false);
                });
        }
    },
    // Let's also make sure the updateStickyPlayerInfo method is comprehensive
    updateStickyPlayerInfo() {
        if (!this.stickyPlayer || !this.currentItem?.songs) return;

        const currentSong = this.currentItem.songs[this.currentTrackIndex];
        if (!currentSong) {
            console.error('No current song found');
            return;
        }

        console.log('Updating sticky player with:', currentSong);

        const trackInfo = this.stickyPlayer.querySelector('#track-info');
        const albumArt = this.stickyPlayer.querySelector('#album-art');
        const songDuration = this.stickyPlayer.querySelector('#song-duration');
        const progressBar = this.stickyPlayer.querySelector('#progress-bar');

        if (trackInfo) trackInfo.textContent = `${currentSong.songArtist} - ${currentSong.songName}`;
        if (albumArt) {
            albumArt.src = currentSong.imgUrl;
            albumArt.alt = `${currentSong.songArtist} - ${currentSong.songName}`;
            this.updateAlbumArtBlur(); // Add this line

        }
        if (songDuration) songDuration.textContent = '0:30'; // Set to max preview time
        if (progressBar) progressBar.value = 0;
    },

    updateProgress() {
        const progressBar = this.stickyPlayer?.querySelector('#progress-bar');
        if (!progressBar) return;

        const duration = this.isPreviewMode ? 30 : this.audioPlayer.duration;
        const percentage = (this.audioPlayer.currentTime / duration) * 100;
        progressBar.value = percentage;
    },

    previousTrack() {
        if (this.currentTrackIndex > 0) {
            const wasPlaying = !this.audioPlayer.paused;
            this.setCurrentTrack(this.currentTrackIndex - 1);
            this.loadTrack(this.currentTrackIndex, this.currentItem.songs);
            if (wasPlaying) {
                this.audioPlayer.play().then(() => {
                    this.startAudioTimer();
                    this.startCountdown();
                    this.updatePlayPauseButtons(true);
                });
            }
        }
    },


    nextTrack() {
        if (this.currentItem?.songs && this.currentTrackIndex < this.currentItem.songs.length - 1) {
            const wasPlaying = !this.audioPlayer.paused;
            this.setCurrentTrack(this.currentTrackIndex + 1);
            this.loadTrack(this.currentTrackIndex, this.currentItem.songs);
            if (wasPlaying) {
                this.audioPlayer.play().then(() => {
                    this.startAudioTimer();
                    this.startCountdown();
                    this.updatePlayPauseButtons(true);
                });
            }
        }
    },


    setCurrentTrack(index) {
        console.log('Setting current track to:', index);
        this.currentTrackIndex = index;

        // Update player info dynamically
        const currentSong = this.currentItem.songs[index];
        this.updatePlayerInfo(currentSong);
    },
    loadTrack(index, tracks, isOwned = false) {
        console.log('Loading track:', {
            index,
            tracksType: Array.isArray(tracks) ? 'array' : 'musicData',
            tracks: tracks,
            isOwned
        });

        // Handle both array of tracks and musicData object
        const tracksArray = Array.isArray(tracks) ? tracks : tracks?.songs;
        if (!tracksArray?.[index]) {
            console.error('Invalid track data:', { index, tracks: tracksArray });
            return;
        }

        const song = tracksArray[index];
        if (!song.audioUrl) {
            console.error('No audio URL found for track:', song);
            return;
        }

        // Set preview/full mode
        this.isPreviewMode = !isOwned;
        this.MAX_PLAY_TIME = this.isPreviewMode ? 30 : null;

        // Store current tracks data
        this.currentItem = Array.isArray(tracks) ? { songs: tracks } : tracks;

        try {
            this.audioPlayer.src = song.audioUrl;
            this.audioPlayer.load();

            // Update UI with preview indicator
            const trackInfo = this.stickyPlayer?.querySelector('#track-info');
            if (trackInfo) {
                trackInfo.innerHTML = `
                ${song.songArtist} - ${song.songName}
                ${this.isPreviewMode ? '<span class="preview-badge">Preview</span>' : ''}
            `;
            }

            this.currentTrackIndex = index;
            this.updatePlayerInfo(song);
            this.updateActiveTrack(index);
            this.resetDurationDisplay();
            this.updateStickyPlayerInfo();
            this.showStickyPlayer();

        } catch (error) {
            console.error('Error loading track:', error);
        }
    },



    updateClaimButtonPosition() {
        const claimButton = document.getElementById('claimButton');
        if (claimButton && claimButton.style.display === 'block') {
            if (this.stickyPlayer && this.stickyPlayer.style.display === 'flex') {
                const playerHeight = this.stickyPlayer.offsetHeight;
                claimButton.style.bottom = `${playerHeight + 20}px`;
            } else {
                claimButton.style.bottom = '20px';
            }
        }
    },

    showStickyPlayer() {
        if (this.stickyPlayer) {
            this.stickyPlayer.style.display = 'flex';
            // Update sticky player info
            this.updateStickyPlayerInfo();
            this.updateClaimButtonPosition();

        }
    },

    hideStickyPlayer() {
        if (this.stickyPlayer) {
            this.stickyPlayer.style.display = 'none';
            this.updateClaimButtonPosition();

        }
    },


    togglePlayPause() {
        console.log('Toggle play/pause, audio state:', {
            paused: this.audioPlayer.paused,
            currentTime: this.audioPlayer.currentTime,
            src: this.audioPlayer.src,
            readyState: this.audioPlayer.readyState
        });

        // First verify we have a valid audio source
        if (!this.audioPlayer.src) {
            console.error('No audio source loaded');
            return;
        }

        if (this.audioPlayer.paused) {
            if (this.audioPlayer.currentTime >= this.MAX_PLAY_TIME) {
                this.audioPlayer.currentTime = 0;
                this.resetAudioTimer();
                this.clearCountdown();
                this.resetDurationDisplay();
            }

            // Only load if not ready
            if (this.audioPlayer.readyState < 3) {
                this.audioPlayer.load();
            }

            console.log('Playing audio, readyState:', this.audioPlayer.readyState);

            // Add a small delay to ensure audio is properly loaded
            setTimeout(() => {
                this.audioPlayer.play()
                    .then(() => {
                        console.log('Playback started successfully');
                        this.startAudioTimer();
                        this.startCountdown();
                        this.updatePlayPauseButtons(true);
                        this.showStickyPlayer();
                    })
                    .catch(error => {
                        console.error('Error playing audio, trying again:', error);
                        this.showErrorToUser("Failed to play audio. Please try again.");
                    });
            }, 100);
        } else {
            console.log('Pausing audio');
            this.audioPlayer.pause();
            this.updatePlayPauseButtons(false);
            // Sticky player remains visible when paused
        }
    },

    // Add this helper if you don't have it already
    showErrorToUser(message) {
        console.error(message);
        // Optionally add UI notification here
    },

    stopPlayback() {
        this.audioPlayer.pause();
        this.audioPlayer.currentTime = 0;
        this.updatePlayPauseButtons(false);
        this.resetAudioTimer();
        this.clearCountdown();
        this.resetDurationDisplay();
        this.hideStickyPlayer();
    },

    startCountdown() {
        this.clearCountdown();

        this.countdownInterval = setInterval(() => {
            const timeLeft = Math.ceil(this.MAX_PLAY_TIME - this.audioPlayer.currentTime);
            this.updateCountdownDisplay(timeLeft);

            if (timeLeft <= 0) {
                this.clearCountdown();
                this.resetDurationDisplay();
            }
        }, 1000);
    },

    clearCountdown() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
            this.countdownInterval = null;
        }
    },

    updateCountdownDisplay(timeLeft) {
        const songItems = document.querySelectorAll('.song-list li');
        const currentSongItem = songItems[this.currentTrackIndex];

        if (currentSongItem) {
            const durationEl = currentSongItem.querySelector('.song-duration');
            if (durationEl) {
                const minutes = Math.floor(timeLeft / 60);
                const seconds = timeLeft % 60;
                durationEl.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;
            }
        }

        const currentSongDuration = document.querySelector('.current-song .song-duration');
        if (currentSongDuration) {
            const minutes = Math.floor(timeLeft / 60);
            const seconds = timeLeft % 60;
            currentSongDuration.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;
        }
    },

    resetDurationDisplay() {
        if (!this.currentItem) return;

        const songItems = document.querySelectorAll('.song-list li');
        songItems.forEach((item, index) => {
            const durationEl = item.querySelector('.song-duration');
            if (durationEl && this.currentItem.songs[index]) {
                durationEl.textContent = this.currentItem.songs[index].duration;
            }
        });

        const currentSongDuration = document.querySelector('.current-song .song-duration');
        if (currentSongDuration && this.currentItem.songs[this.currentTrackIndex]) {
            currentSongDuration.textContent = this.currentItem.songs[this.currentTrackIndex].duration;
        }
    },


    updatePlayPauseButtons(isPlaying) {
        const buttons = document.querySelectorAll('#album-play-pause-btn, #play-pause-btn, #sticky-play-pause-btn');
        buttons.forEach(btn => {
            if (btn) {
                btn.innerHTML = isPlaying ?
                    '<i class="fa-solid fa-pause"></i>' :
                    '<i class="fa-solid fa-play"></i>';
            }
        });
    },


    // And in startAudioTimer():
    startAudioTimer() {
        this.resetAudioTimer();

        if (this.isPreviewMode) {
            this.audioTimer = setTimeout(() => {
                console.log('Preview time limit reached, advancing to next track');
                this.handleTrackEnd();
            }, this.MAX_PLAY_TIME * 1000);
        }
    },

    resetAudioTimer() {
        if (this.audioTimer) {
            clearTimeout(this.audioTimer);
            this.audioTimer = null;
        }
    },

    updatePlayerInfo(song) {
        console.log('Updating player info:', song);
        const songNameElement = document.querySelector('.song-name');
        const songArtistElement = document.querySelector('.song-artist');
        const imageArtistElement = document.querySelector('.image-artist'); // Select the image-artist element


        // Update song name
        if (songNameElement) songNameElement.textContent = song.songName;

        // Update song artist
        if (songArtistElement) songArtistElement.textContent = song.songArtist;

        // Update image artist
        if (imageArtistElement) imageArtistElement.textContent = `Artwork by ${song.imgArtist}`;


        // Optional: Log the changes for debugging
        console.log(`Updated song artist to: ${song.songArtist}`);
    },

    updateActiveTrack(index) {
        console.log('Updating active track:', index);
        const tracks = document.querySelectorAll('.song-list li');
        tracks.forEach(track => track.classList.remove('active-track'));
        if (tracks[index]) {
            tracks[index].classList.add('active-track');
        }
    }
};

export const musicData = {
    title: "When Walls Break",
    subtitle: "Various Artists",
    albumImage: "https://ipfs.io/ipfs/QmNYYYNyPWmCvRtVeNTEf95HSd2awwJNX67VESGEiy4KSw",
    description: "When Walls Break features 8 original tracks paired with 8 unique artworks inspired by the music. This groundbreaking album redefines music ownership with only 100 collector editions available. Release date 19 Nov 2024.",
    isMusicPlayer: true,
    monthlyListeners: 617,
    albumPrice: 80,
    albumSupply: 100,
    albumCoverArtist: "Reece Swanepoel",
    songs: [
        { songArtist: "Josie Field", songName: "Radio Silence", duration: "3:29", audioUrl: 'https://ipfs.io/ipfs/QmesAsFDgqpsfRstW8zZr8BWpbTBhXcD4yfMyxJmA37pgY', imgUrl: 'https://ipfs.io/ipfs/QmXkatbEnZdmoa4BLF7Yp3Xuzuq2NGc28mXerPqEGxPE3M', songPrice: "10", imgArtist: "Natan", songSupply: 100 },
        { songArtist: "Ard Matthews", songName: "You Make it Alright", duration: "4:10", audioUrl: "https://ipfs.io/ipfs/QmcZbVzhZZndgeNbkpdyiEBr84mfhimdXvVn7YNrxywYPb", imgUrl: "https://ipfs.io/ipfs/QmZia4gVFpGwWMmvicosigdmbprev39S3fUKsXVixA2Pen", songPrice: "10", imgArtist: "Teflon Musk", songSupply: 100 },
        { songArtist: "Arno Carstens", songName: "Mortals", duration: "4:43", audioUrl: "https://ipfs.io/ipfs/QmSQpyLaHUL5ZTycn5JNwZxFCdULxpqF3oXUC3qzL12Zjb", imgUrl: "https://ipfs.io/ipfs/QmYgxBaTb1eX5V4i1NruLn51NadbJSPtttQjxEtwuP7sAa", songPrice: "10", imgArtist: "Chemical X", songSupply: 100 },
        { songArtist: "Kahn Morbee", songName: "Buzzhead", duration: "2:35", audioUrl: "https://ipfs.io/ipfs/Qmbx9bD29DSDY2MxoVzp5FDPrzAsSNmg16tmoJLxL6pZ6G", imgUrl: "https://ipfs.io/ipfs/QmSRianGwKtcMFaHRmay79jNTjeTAQRwpm35ehsrnRJzPp", songPrice: "10", imgArtist: "Carole Levy", songSupply: 100 },
        { songArtist: "Laurie Levine", songName: "Grey", duration: "4:54", audioUrl: "https://ipfs.io/ipfs/QmPvsW9vYbKnSCtiJiQfjt7piBF4vTBNrcJazCCvfNbTyw", imgUrl: "https://ipfs.io/ipfs/QmZqiKryNXH8kfePLppMogxeVU1ZSAccdcJdsizuYeZJcU", songPrice: "10", imgArtist: "Grace Hye", songSupply: 100 },
        { songArtist: "Jacques Moolman", songName: "Exile", duration: "4:23", audioUrl: "https://ipfs.io/ipfs/QmU3nsyuyndYNpvknvLW8htPQr61TiyJ5EV7SeEeWu5bpx", imgUrl: "https://ipfs.io/ipfs/QmdBhHArGHABhu2TSYuZxQggGhhSLrcqi4i9MhLWANKn8h", songPrice: "10", imgArtist: "TheArtist", songSupply: 100 },
        { songArtist: "Evert Synman", songName: "I Shouldn't Break", duration: "3:08", audioUrl: "https://ipfs.io/ipfs/QmXTiJzmRWBD6eXGN6hmr7LNcYS6cGoWCpQFaJHitT6KR2", imgUrl: "https://ipfs.io/ipfs/QmUciiTudqTzH7swSkARHr7HCooj1JgPqnrwmMe2v8UZ9h", songPrice: "10", imgArtist: "Ronin", songSupply: 100 },
        { songArtist: "Gazelle", songName: "BBQ to Braai", duration: "1:48", audioUrl: "https://ipfs.io/ipfs/QmdD8hTEuo1uhZmMviTG9nwj5ySDEr3pBbkR5tnuPMQjYq", imgUrl: "https://ipfs.io/ipfs/QmdaiCTmLs1TUynSW9fKtf12PHLHsxvJh2FAmKANBMifBT", songPrice: "10", imgArtist: "Project Indigo", songSupply: 100 }
    ]


};

function validateTrackData(track) {
    if (!track.songName || !track.songArtist || !track.audioUrl) {
        console.error("Invalid track data:", track);
        return false;
    }
    return true;
}

musicData.songs.forEach(validateTrackData);




async function uploadJSONToIPFS(nftConfig) {
    const pinataApiUrl = 'https://api.pinata.cloud/pinning/pinJSONToIPFS';
    const pinataApiKey = process.env.PINATA_API_KEY;
    const pinataSecretApiKey = process.env.PINATA_SECRET_KEY;

    const pinataRequestBody = {
        pinataContent: nftConfig, // Pass the metadata object directly
        pinataMetadata: {
            name: `${nftConfig.name || 'Unnamed'}_metadata.json`,
        },
        pinataOptions: {
            cidVersion: 1
        }
    };

    try {
        const response = await fetch(pinataApiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'pinata_api_key': pinataApiKey,
                'pinata_secret_api_key': pinataSecretApiKey,
            },
            body: JSON.stringify(pinataRequestBody),
        });

        if (!response.ok) {
            const errorBody = await response.text();
            throw new Error(`Failed to upload JSON to IPFS. Status: ${response.status}. Body: ${errorBody}`);
        }

        if (!pinataApiKey || !pinataSecretApiKey) {
            throw new Error('Pinata credentials not configured');
        }

        const responseData = await response.json();
        return responseData.IpfsHash; // Return the IPFS hash
    } catch (error) {
        console.error('Error uploading JSON to IPFS:', error);
        throw error;
    }
}
export const albumMetadata = {
    sip: 16,
    name: musicData.title,
    description: musicData.description,
    image: musicData.albumImage,
    attributes: [
        { trait_type: "Album Price", value: `${musicData.albumPrice} STX` },
        { trait_type: "Album Supply", value: musicData.albumSupply },
        { trait_type: "Cover Artist", value: musicData.albumCoverArtist }
    ],
    properties: {
        tracks: musicData.songs.map(song => ({
            name: song.songName,
            artist: song.imgArtist,
            musician: song.songArtist,
            image: song.imgUrl,
            audio: song.audioUrl,
            genre: "Unknown",
            length: song.duration,
            price: `${song.songPrice} STX`,
            supply: song.songSupply
        })),
        collection: {
            name: musicData.title + " Collection"
        },
        copyright: "© 2024 Gated"
    }
};
// console.log(JSON.stringify(albumMetadata, null, 2));


// Function to create metadata for a single track
const createTrackMetadata = (track, musicData) => {
    return {
        sip: 16,
        name: track.name,
        description: `${track.name} by ${track.musician} and ${track.artist}`,
        image: track.image,
        attributes: [
            { trait_type: "Genre", value: "Unknown" },
            { trait_type: "Length", value: track.duration },
            { trait_type: "Price", value: `${track.songPrice} STX` },
            { trait_type: "Supply", value: track.songSupply }
        ],
        properties: {
            audio: track.audio,
            artist: track.artist,
            musician: track.musician,
            collection: {
                name: musicData.title + " Collection"
            },
            copyright: "© 2024 Gated",
            coverArtist: track.artist,
            trackPrice: `${track.songPrice} STX`,
            trackSupply: track.songSupply,
            albumTitle: musicData.title,
            trackNumber: track.trackNumber
        }
    };
};

// Function to create and upload metadata for all tracks
const createAndUploadTrackMetadata = async (musicData) => {
    const trackMetadataArray = musicData.songs.map((song, index) =>
        createTrackMetadata({
            name: song.songName,
            artist: song.imgArtist,
            musician: song.songArtist,
            image: song.imgUrl,
            audio: song.audioUrl,
            duration: song.duration,
            songPrice: song.songPrice,
            songSupply: song.songSupply,
            trackNumber: index + 1
        }, musicData)
    );

    // Upload each track's metadata and collect the IPFS hashes
    const trackIPFSHashes = [];

    for (let i = 0; i < trackMetadataArray.length; i++) {
        try {
            console.log(`Uploading metadata for track ${i + 1}...`);
            const ipfsHash = await uploadJSONToIPFS(trackMetadataArray[i]);
            trackIPFSHashes.push(ipfsHash);
            console.log(`Track ${i + 1} metadata uploaded. IPFS Hash:`, ipfsHash);
            console.log(`Accessible at: https://gateway.pinata.cloud/ipfs/${ipfsHash}`);
        } catch (error) {
            console.error(`Failed to upload metadata for track ${i + 1}:`, error);
            throw error;
        }
    }

    return {
        trackMetadataArray,
        trackIPFSHashes
    };
};

// Modified handleUpload function to handle both album and track metadata
async function handleUpload() {
    try {
        console.log("Preparing and uploading album metadata...");
        // Upload album metadata
        const albumIpfsHash = await uploadJSONToIPFS(albumMetadata);
        console.log('Album metadata upload successful. IPFS Hash:', albumIpfsHash);
        console.log(`Album metadata accessible at: https://gateway.pinata.cloud/ipfs/${albumIpfsHash}`);

        console.log("\nPreparing and uploading individual track metadata...");
        // Upload track metadata
        const { trackIPFSHashes } = await createAndUploadTrackMetadata(musicData);

        // Log all IPFS hashes together
        console.log('\nAll IPFS Hashes:');
        console.log('Album:', albumIpfsHash);
        trackIPFSHashes.forEach((hash, index) => {
            console.log(`Track ${index + 1}:`, hash);
        });

        // These hashes can now be used to update your smart contract
        return {
            albumHash: albumIpfsHash,
            trackHashes: trackIPFSHashes
        };

    } catch (error) {
        console.error('Failed to upload metadata:', error);
        throw error;
    }
}

//Example usage
// handleUpload()
//     .then(({ albumHash, trackHashes }) => {
//         console.log('\nYou can now update your smart contract with these hashes using:');
//         console.log('contract.updateMetadataUris(');
//         console.log(`  "${albumHash}",`);
//         console.log('  (list');
//         trackHashes.forEach(hash => console.log(`    "${hash}"`));
//         console.log('  )');
//         console.log(')');
//     })
//     .catch(console.error);