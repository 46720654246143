import { initNFTFallEffect } from './snowEffect.js';

// pageWithEdit.js
export class EditSidebar {
  constructor(router) {
    console.log('EditSidebar constructor starting with router:', router);
    this.router = router;
    this.init();
    this.initializeSocialLinksToggle();
  }

  initializeSocialLinks(pageData) {
    const socialLinksToggle = document.getElementById('socialLinksEnabled');
    const socialLinksFields = document.getElementById('socialLinksFields');

    // Check if we have social links data
    const socialLinks = pageData?.socialLinks?.links || {};
    const hasSocialLinks = Object.values(socialLinks).some(link => link);

    if (socialLinksToggle && socialLinksFields) {
      // Set toggle state based on existing data
      socialLinksToggle.checked = hasSocialLinks || pageData?.socialLinks?.enabled;

      // Show/hide fields based on toggle state
      socialLinksFields.classList.toggle('hidden', !socialLinksToggle.checked);

      // Set existing values if they exist
      if (hasSocialLinks) {
        if (socialLinks.x) {
          document.getElementById('xUrl').value = socialLinks.x;
        }
        if (socialLinks.discord) {
          document.getElementById('discordUrl').value = socialLinks.discord;
        }
        if (socialLinks.website) {
          document.getElementById('websiteUrl').value = socialLinks.website;
        }
      }

      // Add toggle event listener
      socialLinksToggle.addEventListener('change', (e) => {
        socialLinksFields.classList.toggle('hidden', !e.target.checked);
      });
    }
  }

  initializeSocialLinksToggle() {
    const toggle = document.getElementById('socialLinksEnabled');
    const fields = document.getElementById('socialLinksFields');

    if (toggle && fields) {
      toggle.addEventListener('change', (e) => {
        fields.classList.toggle('hidden', !e.target.checked);
      });
    }
  }

  async getUserStxAddress() {
    // Simply return the address since we know authService exists
    return this.router.authService.getCurrentAddress();
  }

  async updateVisibility(matchingPage, cleanAddress) {
    const userAddress = await this.getUserStxAddress();
    if (userAddress && userAddress.toLowerCase() === cleanAddress.toLowerCase()) {
      this.setPageData(matchingPage);
      this.showEditButton(true);
    } else {
      this.showEditButton(false);
    }
  }

  getBaseStyles() {
    return `
      .edit-sidebar {
        position: fixed;
        top: 0;
        left: -350px;
        width: 350px;
        height: 100vh;
        background: white;
        box-shadow: 2px 0 8px rgba(0,0,0,0.1);
        transition: left 0.3s ease;
        z-index: 1000;
      }

      .edit-sidebar.visible {
        left: 0;
      }

      .edit-sidebar-content {
        padding: 20px;
      }

      .close-btn {
        position: absolute;
        right: 20px;
        top: 20px;
        border: none;
        font-size: 24px;
        cursor: pointer;
      }

      .form-group {
        margin-bottom: 20px;
      }

      .form-group label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
        display: flex;
      }

      .form-group input,
      .form-group textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      .form-group textarea {
        min-height: 100px;
      }

      .save-btn {
        color: white;
        padding: 12px 18px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
      }

      .save-btn:hover {
      padding: 12px 18px;
      border: none;
      background: #2b2a31;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      gap: 5px;
      }

      .edit-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        color: white;
        padding: 12px 24px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        display: none;
        z-index: 999;
      }

      .edit-button:hover {
      }
    `;
  }

  init() {
    // Create sidebar HTML with expanded form
    const sidebarHTML = `
    <div id="editSidebar" class="edit-sidebar">
        <div class="edit-sidebar-content">
        <div class="close-btn-container">
            <button class="close-btn">&times;</button>
        </div>
            <h2>Edit Page</h2>
            <form id="editPageForm">
                <div class="form-group">
                    <label for="pageTitle">Title</label>
                    <input type="text" id="pageTitle" name="title" required>
                </div>
                
                <div class="form-group">
                    <label for="pageDescription">Description</label>
                    <textarea id="pageDescription" name="description" required></textarea>
                </div>

                <div class="form-group">
                    <label class="status-toggle">
                        <span>Active Status</span>
                        <input type="checkbox" id="activeStatus" name="active">
                        <span class="toggle-slider"></span>
                    </label>
                </div>

                <div class="form-group">
                    <label class="status-toggle">
                        <span>Social Links</span>
                        <input type="checkbox" id="socialLinksEnabled" name="socialLinksEnabled">
                        <span class="toggle-slider"></span>
                    </label>
                </div>

                <div id="socialLinksFields" class="form-group social-fields hidden">
                    <div class="social-input">
                        <label for="xUrl">X</label>
                        <input type="url" id="xUrl" name="xUrl" 
                              placeholder="https://x.com/username"
                              class="social-url-input">
                    </div>
                    
                    <div class="social-input">
                        <label for="websiteUrl">Website</label>
                        <input type="url" id="websiteUrl" name="websiteUrl" 
                              placeholder="https://example.com"
                              class="social-url-input">
                    </div>
                    
                    <div class="social-input">
                        <label for="discordUrl">Discord</label>
                        <input type="url" id="discordUrl" name="discordUrl" 
                              placeholder="https://discord.com/username"
                              class="social-url-input">
                    </div>
                </div>

                <div class="form-group">
                    <label class="festive-toggle">
                        <span>Festive Mode</span>
                        <input type="checkbox" id="festiveMode" name="festive">
                        <span class="toggle-slider"></span>
                    </label>
                </div>

                <div id="nftDropdownContainer" class="form-group nft-dropdown-container" style="display: none;">
                    <label for="nftSelect">Select NFT</label>
                    <select id="nftSelect" class="nft-select">
                        <option value="">Select an NFT</option>
                    </select>
                    <div id="nftPreview" class="nft-preview"></div>
                </div>

                <button type="submit" class="save-btn">Update Page</button>
            </form>
        </div>
    </div>
`;

    // Add additional styles
    const styles = `
            ${this.getBaseStyles()}
            
            /* Toggle Styles */
            .status-toggle, .festive-toggle {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 10px 0;
            }

            .toggle-slider {
                position: relative;
                display: inline-block;
                width: 50px;
                height: 24px;
                background: #ccc;
                border-radius: 12px;
                cursor: pointer;
                transition: 0.3s;
            }

            .toggle-slider:before {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: white;
                top: 2px;
                left: 2px;
                transition: 0.3s;
            }

            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"]:checked + .toggle-slider {
                background: #4CAF50;
            }

            input[type="checkbox"]:checked + .toggle-slider:before {
                transform: translateX(26px);
            }

            /* NFT Dropdown Styles */
            .nft-dropdown-container {
                margin-top: 15px;
            }

            .nft-select {
                width: 100%;
                padding: 8px;
                border: 1px solid #ddd;
                border-radius: 4px;
                margin-bottom: 10px;
            }

            .nft-preview {
              display: none;  /* Hide by default */
              align-items: center;
              gap: 10px;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 4px;
              margin-top: 10px;
                  }

                     /* Only show when it has content */
    .nft-preview:not(:empty) {
        display: flex;
    }

            .nft-preview img {
                width: 40px;
                height: 40px;
                border-radius: 4px;
                object-fit: cover;
            }
        `;

    // Add styles to document
    const styleSheet = document.createElement('style');
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);

    // Add sidebar to document
    document.body.insertAdjacentHTML('beforeend', sidebarHTML);

    // Add edit button
    const editButton = document.createElement('button');
    editButton.className = 'edit-button';
    editButton.textContent = 'Edit Page';
    document.body.appendChild(editButton);

    // Store elements
    this.sidebar = document.getElementById('editSidebar');
    this.editButton = editButton;
    this.form = document.getElementById('editPageForm');

    // Add event listeners
    this.addEventListeners();
  }

  addEventListeners() {
    // Close button
    this.sidebar.querySelector('.close-btn').addEventListener('click', () => {
      this.hide();
    });

    // Edit button
    this.editButton.addEventListener('click', () => {
      this.show();
    });

    // Festive mode toggle
    const festiveToggle = document.getElementById('festiveMode');
    const nftDropdown = document.getElementById('nftDropdownContainer');
    festiveToggle?.addEventListener('change', (e) => {
      nftDropdown.style.display = e.target.checked ? 'block' : 'none';

      if (e.target.checked) {
        this.loadNFTs();
      } else {
        // Clean up NFT fall effect when toggling off
        if (window.currentNFTFallEffect) {
          try {
            document.body.removeChild(window.currentNFTFallEffect.canvas);
            window.currentNFTFallEffect = null;
            console.log('NFT Fall Effect cleaned up successfully');
          } catch (error) {
            console.error('Error cleaning up NFT Fall Effect:', error);
          }
        }
      }
    });

    // Form submission
    this.form.addEventListener('submit', async (e) => {
      e.preventDefault();
      const formData = new FormData(this.form);

      try {

        // Create temporary page card with required structure
        const virtualPageCard = document.createElement('div');
        virtualPageCard.id = `page-${this.currentPageId}`;

        // Add NFT dropdown container
        const nftDropdownContainer = document.createElement('div');
        nftDropdownContainer.className = 'nft-dropdown-container';

        // Get NFT data from the actual select
        const originalSelect = document.getElementById('nftSelect');
        const festiveMode = document.getElementById('festiveMode');

        // Collect social links data
        const socialLinksEnabled = document.getElementById('socialLinksEnabled')?.checked || false;
        const socialLinksData = {
          twitter: document.getElementById('xUrl')?.value || '',
          website: document.getElementById('websiteUrl')?.value || '',
          discord: document.getElementById('discordUrl')?.value || ''
        };

        // Create page data object with all necessary information
        const pageUpdateData = {
          id: this.currentPageId,
          title: formData.get('title'),
          description: formData.get('description'),
          slug: this.router.sanitizeUrl(formData.get('title')),
          stxAddress: this.router.currentStxAddress,
          active: document.getElementById('activeStatus').checked,
          contractType: 'gated-pages',
          socialLinks: {
            enabled: socialLinksEnabled,
            links: socialLinksData
          }
        };

        if (originalSelect && festiveMode.checked && originalSelect.selectedIndex > 0) {
          const selectedOption = originalSelect.options[originalSelect.selectedIndex];

          // Create select with the same structure as your dashboard form
          const select = document.createElement('select');
          select.className = 'nft-select';

          // Important: Keep the first empty option
          const defaultOption = document.createElement('option');
          defaultOption.value = "";
          defaultOption.textContent = "Select an NFT";
          select.appendChild(defaultOption);

          // Add the selected NFT option
          const option = document.createElement('option');
          option.value = selectedOption.value;
          option.textContent = selectedOption.textContent;
          option.dataset.imageUrl = selectedOption.dataset.imageUrl;
          option.selected = true;
          select.appendChild(option);

          // Make sure select has selectedIndex > 0 as the modal checks for this
          select.selectedIndex = 1;

          nftDropdownContainer.appendChild(select);
        }

        virtualPageCard.appendChild(nftDropdownContainer);

        // Create virtual toggle
        const virtualToggle = document.createElement('input');
        virtualToggle.type = 'checkbox';
        virtualToggle.id = `festive-gated-pages-${this.currentPageId}`;
        virtualToggle.checked = festiveMode.checked;
        virtualPageCard.appendChild(virtualToggle);

        // Add to document temporarily
        document.body.appendChild(virtualPageCard);

        // Store in router
        this.router.currentPageCard = virtualPageCard;
        this.router.currentToggle = virtualToggle;

        await this.router.showUpdateConfirmation(
          this.currentPageId,
          formData.get('title'),
          formData.get('description'),
          this.router.sanitizeUrl(formData.get('title')),
          this.router.currentStxAddress,
          document.getElementById('activeStatus').checked,
          'gated-pages',
          {
            enabled: document.getElementById('socialLinksEnabled').checked,
            links: {
              x: document.getElementById('xUrl').value,
              discord: document.getElementById('discordUrl').value,
              website: document.getElementById('websiteUrl').value
            }
          }
        );
        document.body.removeChild(virtualPageCard);
        this.hide();
      } catch (error) {
        console.error('Error in update flow:', error);
        alert('Failed to update page. Please try again.');
      }
    });

  }

  async updatePage(pageData) {
    try {
      // Collect social links data
      const socialLinksData = {
        enabled: document.getElementById('socialLinksEnabled')?.checked || false,
        links: {
          twitter: document.getElementById('xUrl')?.value || '',
          website: document.getElementById('websiteUrl')?.value || '',
          discord: document.getElementById('discordUrl')?.value || ''
        }
      };

      // Use the router's existing update flow
      const result = await this.router.updatePage({
        id: this.currentPageId,
        title: pageData.title,
        description: pageData.description,
        // Preserve existing values
        active: pageData.active,
        contractType: 'gated-pages',
        // Add slug if needed
        slug: this.router.sanitizeUrl(pageData.title),
        // Add metadata with social links
        metadata: {
          ...pageData.metadata, // Preserve any existing metadata
          socialLinks: socialLinksData
        }
      });

      if (result) {
        this.hide();
      }
      // Let the router handle any necessary UI updates
    } catch (error) {
      console.error('Error updating page:', error);
      throw error; // Let the form handler handle the error
    }
  }

  // Modify setPageData to store full page data
  setPageData(pageData) {
    console.log('Setting page data:', pageData);
    this.currentPageId = pageData.id;
    this.currentPageData = pageData;

    // Set basic form values
    this.form.title.value = pageData.title;
    this.form.description.value = pageData.description;

    // Handle social links data
    const socialLinksEnabled = document.getElementById('socialLinksEnabled');
    const socialLinksFields = document.getElementById('socialLinksFields');

    if (socialLinksEnabled && socialLinksFields) {
      // Get social links data from the correct nested location
      const socialLinksData = pageData.metadata?.properties?.meta?.content?.socialLinks;

      console.log('Found social links data:', socialLinksData);

      if (socialLinksData) {
        // Set toggle state based on enabled property
        socialLinksEnabled.checked = socialLinksData.enabled;
        socialLinksFields.classList.toggle('hidden', !socialLinksData.enabled);

        // Set link values if they exist
        if (socialLinksData.links) {
          const links = socialLinksData.links;
          const xInput = document.getElementById('xUrl');
          const websiteInput = document.getElementById('websiteUrl');
          const discordInput = document.getElementById('discordUrl');

          console.log('Setting social link values:', links);

          if (xInput) xInput.value = links.x || '';
          if (websiteInput) websiteInput.value = links.website || '';
          if (discordInput) discordInput.value = links.discord || '';
        }
      } else {
        console.log('No social links data found');
        socialLinksEnabled.checked = false;
        socialLinksFields.classList.add('hidden');
      }
    }

    // Rest of your existing code for active status and festive mode...
    const activeToggle = document.getElementById('activeStatus');
    if (activeToggle) {
      if (pageData.value?.active?.value !== undefined) {
        activeToggle.checked = pageData.value.active.value === true;
      } else if (pageData.active?.value !== undefined) {
        activeToggle.checked = pageData.active.value === true;
      } else {
        activeToggle.checked = !!pageData.active;
      }
    }

    // Handle festive mode and NFT dropdown
    const festiveToggle = document.getElementById('festiveMode');
    const nftDropdown = document.getElementById('nftDropdownContainer');

    if (festiveToggle && pageData.metadata?.attributes) {
      const festiveMode = pageData.metadata.attributes.find(
        attr => attr.trait_type === 'Festive Mode'
      );
      festiveToggle.checked = festiveMode?.value === 'On';

      if (nftDropdown) {
        nftDropdown.style.display = festiveMode?.value === 'On' ? 'block' : 'none';
        if (festiveMode?.value === 'On') {
          this.loadNFTs();
        }
      }
    }
  }


  show() {
    this.sidebar.classList.add('visible');
  }

  hide() {
    this.sidebar.classList.remove('visible');
  }

  showEditButton(show) {
    this.editButton.style.display = show ? 'block' : 'none';
  }

  cleanup() {
    // Remove event listeners
    this.sidebar.querySelector('.close-btn').removeEventListener('click', this.hide);
    this.editButton.removeEventListener('click', this.show);

    // Remove elements
    this.sidebar.remove();
    this.editButton.remove();
  }

  async loadNFTs() {
    const nftDropdown = document.getElementById('nftDropdownContainer');
    const festiveNFTData = this.currentPageData?.metadata?.attributes?.find(
      attr => attr.trait_type === 'Festive NFT'
    );

    await this.populateNFTOptions(nftDropdown, festiveNFTData);
  }

  checkImageLoads(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  async populateNFTOptions(dropdownContainer, festiveNFTData) {
    const select = dropdownContainer.querySelector('.nft-select');
    if (!select) return;
    console.log("Step 6: Populating dropdown with NFTs");

    // Hide the select and preview initially
    select.style.display = 'none';
    const loadingMessage = document.createElement('div');
    loadingMessage.textContent = 'Loading NFTs...';
    dropdownContainer.insertBefore(loadingMessage, select);

    try {
      const userAddress = await this.getUserStxAddress();
      console.log('Got user address:', userAddress);
      
        const nftData = await this.fetchNFTs(userAddress);
        console.log("Step 6.2: NFTs Found:", nftData);

        if (!nftData || !nftData.results) {
            throw new Error('No NFT data available');
        }

        // Clear existing options except the first (default) option
        const defaultOption = select.querySelector('option[value=""]');
        const currentOption = select.querySelector('option[selected]');
        select.innerHTML = '';
        if (defaultOption) select.appendChild(defaultOption);
        if (currentOption) select.appendChild(currentOption);

        // Create or find preview div
        const previewDiv = dropdownContainer.querySelector('.nft-preview') || document.createElement('div');
        previewDiv.className = 'nft-preview';
        previewDiv.style.marginTop = '10px';
        previewDiv.style.display = 'none'; // Hide preview initially

        if (!dropdownContainer.querySelector('.nft-preview')) {
            dropdownContainer.appendChild(previewDiv);
        }

        // Array to collect valid NFTs
        const validNFTs = [];

        // Process all NFTs first
        for (const nft of nftData.results) {
            const nftIdentifier = nft.asset_identifier;
            const nftId = nft.value.repr.replace('u', "");
            const [contractAddress, assetName] = nftIdentifier.split('::');

            try {
                const metadataUrl = `https://api.hiro.so/metadata/v1/nft/${contractAddress}/${nftId}`;
                const response = await fetch(metadataUrl);

                if (!response.ok) continue;

                const metadata = await response.json();
                const imageUrl = metadata.metadata.cached_thumbnail_image;

                if (!imageUrl || !(await this.checkImageLoads(imageUrl))) {
                    continue;
                }

                validNFTs.push({
                    identifier: nftIdentifier,
                    id: nftId,
                    name: assetName,
                    imageUrl: imageUrl
                });

            } catch (error) {
                console.log(`Skipping NFT ${assetName} #${nftId} - error occurred`);
                continue;
            }
        }

        // Only proceed if we found valid NFTs
        if (validNFTs.length > 0) {
            // Add all valid NFTs to select
            validNFTs.forEach(nft => {
                const option = document.createElement('option');
                option.value = nft.identifier;
                option.textContent = `${nft.name} #${nft.id}`;
                option.dataset.imageUrl = nft.imageUrl;
                option.dataset.nftId = nft.id;

                if (nft.identifier === festiveNFTData?.identifier) {
                    option.selected = true;
                }

                select.appendChild(option);
            });

            // Remove loading message and show select
            loadingMessage.remove();
            select.style.display = 'block';

            // Setup change listener
            select.addEventListener('change', (e) => {
                const handleNFTChange = async () => {
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    const imageUrl = selectedOption.dataset.imageUrl;

                    if (imageUrl) {
                        previewDiv.innerHTML = `
                            <img src="${imageUrl}" 
                                alt="NFT Preview" 
                                style="max-width: 100px; height: auto; border-radius: 4px;">
                        `;
                        previewDiv.style.display = 'block';

                        // Clean up existing NFT fall effect
                        if (window.currentNFTFallEffect) {
                            const container = document.querySelector('.profile-section');
                            if (container && container.contains(window.currentNFTFallEffect.canvas)) {
                                container.removeChild(window.currentNFTFallEffect.canvas);
                            }
                            window.currentNFTFallEffect = null;
                        }

                        // Initialize new fall effect in container
                        const container = document.querySelector('.profile-section');
                        if (container) {
                            window.currentNFTFallEffect = initNFTFallEffect(imageUrl);
                            const canvas = window.currentNFTFallEffect.canvas;
                          
                        }
                    } else {
                        previewDiv.innerHTML = '';
                        previewDiv.style.display = 'none';
                    }
                };

                handleNFTChange().catch(console.error);
            });
        } else {
            loadingMessage.textContent = 'No valid NFTs found';
        }

    } catch (error) {
        console.error('Error populating NFT options:', error);
        loadingMessage.textContent = 'Error loading NFTs';
        throw error;
    }
}

  async fetchNFTs(userAddress) {
    console.log('Fetching NFTs for address:', userAddress);
    const nftHoldingsUrl = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddress}&limit=50`;
    console.log('Fetching from URL:', nftHoldingsUrl);

    try {
      const response = await fetch(nftHoldingsUrl);
      console.log('NFT API response status:', response.status);

      if (!response.ok) {
        throw new Error(`Failed to fetch NFT holdings: ${response.status}`);
      }

      const data = await response.json();
      console.log('NFT API response data:', data);
      return data;
    } catch (error) {
      console.error('Error fetching NFTs:', error);
      throw error;
    }
  }

  // Update the existing updatePage method to handle NFT data
  async updatePage(pageData) {
    try {
      const festiveToggle = document.getElementById('festiveMode');
      const nftSelect = document.getElementById('nftSelect');

      let nftData = {};
      if (festiveToggle && festiveToggle.checked && nftSelect && nftSelect.value) {
        const selectedOption = nftSelect.options[nftSelect.selectedIndex];
        nftData = {
          festiveNFT: selectedOption.value,
          selectedNFTName: selectedOption.textContent,
          selectedNFTImage: selectedOption.dataset.imageUrl
        };
      }

      const result = await this.router.updatePage({
        id: this.currentPageId,
        title: pageData.title,
        description: pageData.description,
        active: document.getElementById('activeStatus').checked,
        contractType: 'gated-pages',
        slug: this.router.sanitizeUrl(pageData.title),
        festive: festiveToggle?.checked || false,
        ...nftData
      });

      if (result) {
        this.hide();
        // Cleanup NFT effect if exists
        if (window.currentNFTFallEffect) {
          document.body.removeChild(window.currentNFTFallEffect.canvas);
          window.currentNFTFallEffect = null;
        }
      }
    } catch (error) {
      console.error('Error updating page:', error);
      throw error;
    }
  }

}