class NFTFallEffect {
    constructor(imageUrl) {
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.fallingNFTs = [];
        this.nftImage = new Image();
        this.nftImage.src = imageUrl;
        this.imageSize = 30; // Size of the falling NFTs

        // Configure canvas
        this.canvas.style.position = 'fixed';
        this.canvas.style.top = '0';
        this.canvas.style.left = '0';
        this.canvas.style.width = '100%';
        this.canvas.style.height = '100%';
        this.canvas.style.pointerEvents = 'none';
        this.canvas.style.zIndex = '1000';

        // Initialize after image loads
        this.nftImage.onload = () => {
            this.resize();
            this.createFallingNFTs(50); // Create 50 falling NFTs
            this.animate();
        };

        // Event listeners
        window.addEventListener('resize', () => this.resize());
    }

    resize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }

    createFallingNFTs(count) {
        for (let i = 0; i < count; i++) {
            this.fallingNFTs.push({
                x: Math.random() * this.width,
                y: Math.random() * this.height,
                speed: Math.random() * 2 + 1,
                drift: Math.random() * 1 - 0.5,
                rotation: Math.random() * 360,
                rotationSpeed: (Math.random() - 0.5) * 2
            });
        }
    }

    drawNFT(nft) {
        this.ctx.save();
        this.ctx.translate(nft.x + this.imageSize / 2, nft.y + this.imageSize / 2);
        this.ctx.rotate(nft.rotation * Math.PI / 180);
        this.ctx.drawImage(
            this.nftImage,
            -this.imageSize / 2,
            -this.imageSize / 2,
            this.imageSize,
            this.imageSize
        );
        this.ctx.restore();
    }

    updateNFT(nft) {
        nft.y += nft.speed;
        nft.x += nft.drift;
        nft.rotation += nft.rotationSpeed;

        // Reset when it goes off screen
        if (nft.y > this.height) {
            nft.y = -this.imageSize;
            nft.x = Math.random() * this.width;
        }

        // Wrap around sides
        if (nft.x > this.width) nft.x = -this.imageSize;
        if (nft.x < -this.imageSize) nft.x = this.width;
    }

    animate() {
        this.ctx.clearRect(0, 0, this.width, this.height);

        // Draw and update falling NFTs
        this.fallingNFTs.forEach(nft => {
            this.updateNFT(nft);
            this.drawNFT(nft);
        });

        requestAnimationFrame(() => this.animate());
    }
}

// Initialize NFT fall effect
function initNFTFallEffect(imageUrl) {
    const effect = new NFTFallEffect(imageUrl);
    document.body.appendChild(effect.canvas);
    return effect; // Return the instance so we can remove it later if needed
}

// Export for use with webpack
export { initNFTFallEffect };