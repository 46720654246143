// loader.js

class LoaderComponent {
    constructor() {
        this.loaderElement = null;
        this.updateInterval = null;
        this.startTime = null;
        this.loadingMessages = [
            "Accessing blockchain data...",
            "Retrieving necessary information...",
            "Loading metadata...",
            "Preparing NFTs...",
            "Final preparations..."
        ];
        this.currentMessageIndex = 0;
        this.createLoader();
    }

    createLoader() {
        const loader = document.createElement('div');
        loader.className = 'loader-container';
        loader.innerHTML = `
      <div class="loader-content">
        <div class="loader-spinner"></div>
        <div class="loader-text">${this.loadingMessages[0]}</div>
      </div>
    `;
        this.loaderElement = loader;

        // Add styles to document head
        const style = document.createElement('style');
        style.textContent = `
      .loader-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
        backdrop-filter: blur(5px);
      }

      .loader-content {
        text-align: center;
      }

      .loader-spinner {
        width: 50px;
        height: 50px;
        border: 5px solid #f3f3f3;
        border-top: 5px solid #d2580b;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        margin: 0 auto 20px auto;
      }

      .loader-text {
        color: #333;
        font-size: 16px;
        font-family: Arial, sans-serif;
        min-height: 20px;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
      }

      .loader-text.fade {
        opacity: 0;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      .loader-container.fade-out {
        opacity: 0;
        transition: opacity 0.3s ease-out;
      }
    `;
        document.head.appendChild(style);
    }

    startMessageCycle() {
        this.startTime = Date.now();
        this.currentMessageIndex = 0;

        const updateMessage = () => {
            const textElement = this.loaderElement.querySelector('.loader-text');
            const elapsedTime = Date.now() - this.startTime;

            // Stop if we've reached 10 seconds
            if (elapsedTime >= 10000) {
                if (this.updateInterval) {
                    clearInterval(this.updateInterval);
                    this.updateInterval = null;
                }
                return;
            }

            // Add fade effect
            textElement.classList.add('fade');

            setTimeout(() => {
                this.currentMessageIndex = (this.currentMessageIndex + 1) % this.loadingMessages.length;
                textElement.textContent = this.loadingMessages[this.currentMessageIndex];
                textElement.classList.remove('fade');
            }, 300); // Half of the interval for smooth transition
        };

        // Update every 2 seconds
        this.updateInterval = setInterval(updateMessage, 2000);
    }

    show() {
        if (!document.body.contains(this.loaderElement)) {
            document.body.appendChild(this.loaderElement);
        }
        this.loaderElement.style.display = 'flex';
        this.loaderElement.classList.remove('fade-out');
        this.startMessageCycle();
    }

    hide() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
            this.updateInterval = null;
        }

        this.loaderElement.classList.add('fade-out');
        setTimeout(() => {
            this.loaderElement.style.display = 'none';
            this.loaderElement.classList.remove('fade-out');
        }, 300);
    }

    updateText(text) {
        const textElement = this.loaderElement.querySelector('.loader-text');
        if (textElement) {
            textElement.classList.add('fade');
            setTimeout(() => {
                textElement.textContent = text;
                textElement.classList.remove('fade');
            }, 300);
        }
    }

    // Method to add custom loading messages
    setCustomMessages(messages) {
        if (Array.isArray(messages) && messages.length > 0) {
            this.loadingMessages = messages;
        }
    }
}

// Initialize loader as a singleton
const loader = new LoaderComponent();
export default loader;