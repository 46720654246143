// pageConfig.js
const PINATA_API_KEY = process.env.PINATA_API_KEY;
const PINATA_SECRET_KEY = process.env.PINATA_SECRET_KEY;

const handlePageUpdate = async (updateData) => {
    console.log("Contract Type:", updateData.contractType);
    console.log("Update Data Structure:", updateData);
    console.log("Full updateData:", JSON.stringify(updateData, null, 2));

    // Add festiveNFT to destructuring
    const {
        id,
        title,
        description,
        active,
        festive = false,
        festiveNFT = null,
        selectedNFTName = null,  // Add this to capture NFT name
        selectedNFTImage = null,  // Add this to capture NFT image URL
        metadata = {},  // Include metadata from existing data
        darkMode = false,  // Add this to capture dark mode status
        profilePhoto = { enabled: false, src: null }  // Add this to capture profile photo status and source
    } = updateData;


    // Get social links from metadata
    const socialLinksData = updateData.metadata?.socialLinks || {
        enabled: false,
        links: {
            x: '',
            discord: '',
            website: ''
        }
    };


    console.log('Starting page update process:', {
        id,
        title,
        description,
        active,
        festive,
        festiveNFT,
        selectedNFTName,
        selectedNFTImage,
        socialLinks: socialLinksData,
        darkMode,  // Log dark mode status
        profilePhoto  // Log profile photo status and source
    });

    try {
        const imageCid = "bafkreifgvle7pvczsnxvb72j224fkxabctadqsfxl3jkrhw6gyhmrk3vua";
        const pageMetadata = createPageMetadata({
            ...updateData,
            festive: festive || false,
            festiveNFT,
            selectedNFTName,
            selectedNFTImage,
            socialLinks: socialLinksData,
            imageCid,
            darkMode,  // Include dark mode status
            profilePhoto  // Include profile photo status and source
        });

        console.log('Generated metadata:', JSON.stringify(pageMetadata, null, 2));
        const ipfsHash = await uploadPageMetadataToIPFS(pageMetadata);
        console.log('Uploaded to IPFS:', ipfsHash);

        return {
            success: true,
            ipfsHash,
            metadata: pageMetadata
        };
    } catch (error) {
        console.error('Error in page update process:', error);
        throw error;
    }
};

const createPageMetadata = (page, additionalSettings) => {
    if (!page.title || !page.description || !page.owner) {
        throw new Error('Missing required metadata fields');
    }

    const slugTitle = page.title.toLowerCase().replace(/\s+/g, '-');

    // Base attributes array
    const attributes = [
        { trait_type: "ID", value: page.id },
        { trait_type: "Page Status", value: page.active ? "Active" : "Inactive" },
        { trait_type: "Festive Mode", value: page.festive ? "On" : "Off" }
    ];

    // Add festive NFT attribute if it exists
    if (page.festive && page.festiveNFT) {
        attributes.push({
            trait_type: "Festive NFT",
            value: page.festiveNFT
        });
    }

    // Add dark mode attribute
    attributes.push({
        trait_type: "Dark Mode",
        value: page.darkMode ? "On" : "Off"
    });

    // Add profile photo attribute if enabled
    if (page.profilePhoto.enabled) {
        attributes.push({
            trait_type: "Profile Photo",
            value: page.profilePhoto.src
        });
    }

    // Create festive content object
    const festiveContent = page.festive ? {
        enabled: true,
        nft: {
            identifier: page.festiveNFT,
            name: page.selectedNFTName,
            imageUrl: page.selectedNFTImage
        }
    } : {
        enabled: false
    };

    // Add social links data
    const socialLinks = {
        enabled: page.socialLinks?.enabled || false,
        links: {
            x: page.socialLinks?.links?.x || '',
            discord: page.socialLinks?.links?.discord || '',
            website: page.socialLinks?.links?.website || ''
        }
    };

    // Rest of metadata structure
    return {
        sip: 16,
        name: page.title,
        description: page.description,
        image: page.imageCid ? `ipfs://${page.imageCid}` : null,
        external_url: `https://${page.owner.toLowerCase()}.gated.so/#/${slugTitle}`,
        attributes,
        collection: {
            name: "Gated Pages",
            family: "Gated",
            creator: page.owner
        },
        properties: {
            files: {},
            attrs: {},
            meta: {
                blockchain: 'Stacks',
                copyright: `© ${new Date().getFullYear()} Gated.so`,
                version: "1.0",
                display: page.display || {},
                content: {
                    ...(page.content || {}),
                    slug: slugTitle,
                    url: `${page.owner.toLowerCase()}.gated.so/#/${slugTitle}`,
                    festive: festiveContent,
                    socialLinks: socialLinks,
                    profilePhoto: page.profilePhoto,  // Add profile photo status and source
                    nftCollection: page.metadata?.nftCollection ? {
                        enabled: true,
                        name: page.metadata.nftCollection.name,
                        items: page.metadata.nftCollection.items.map(nft => ({
                            name: nft.name,
                            image: nft.image
                        }))
                    } : null
                },
                security: page.security || {}
            }
        }
    };
};

// Function to upload metadata to IPFS
const uploadPageMetadataToIPFS = async (metadata) => {
    const pinataApiUrl = 'https://api.pinata.cloud/pinning/pinJSONToIPFS';
    
    if (!PINATA_API_KEY || !PINATA_SECRET_KEY) {
        throw new Error('Missing Pinata credentials');
    }
    const fileName = `${metadata.properties.owner}_${metadata.name || 'page'}_metadata.json`;

    const pinataRequestBody = {
        pinataContent: metadata,
        pinataMetadata: {
            name: fileName,
            keyvalues: {
                owner: metadata.properties.owner,
                title: metadata.name,
                timestamp: new Date().toISOString()
            }
        },
        pinataOptions: {
            cidVersion: 1
        }
    };
    
    try {
        console.log('Sending request to Pinata...', pinataRequestBody);
        const response = await fetch(pinataApiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'pinata_api_key': PINATA_API_KEY,
                'pinata_secret_api_key': PINATA_SECRET_KEY,
            },
            body: JSON.stringify(pinataRequestBody),
        });

        if (!response.ok) {
            const errorBody = await response.text();
            throw new Error(`Failed to upload JSON to IPFS. Status: ${response.status}. Body: ${errorBody}`);
        }

        const responseData = await response.json();
        return responseData.IpfsHash;
    } catch (error) {
        console.error('Error uploading JSON to IPFS:', error);
        throw error;
    }
};


export {
    createPageMetadata,
    uploadPageMetadataToIPFS,
    handlePageUpdate
};